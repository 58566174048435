import { Container, ThemeProvider, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import DesktopAppBar from '../shared/components/DesktopAppBar'
import DesktopFooter from '../shared/components/DesktopFooter';
import { desktopTheme } from '../themeDesktop';

const VendorLayout = () => {

    const aboveSM = useMediaQuery(desktopTheme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={desktopTheme}>
            <section className='desktop-background'>
                <DesktopAppBar />
                <Container>
                    <Outlet />
                </Container>
                {aboveSM && <DesktopFooter />}
            </section>
        </ThemeProvider>
    )
}

export default VendorLayout