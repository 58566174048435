import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, Box, Button, Input, Link, Modal, Tooltip, Typography, styled } from '@mui/material';
import useBrochureService from '../../shared/services/BrochureService';
import { ASSET_TYPE, STATUS_CODES, BROCHURE_TYPES } from '../../shared/constants/AppConsts';
import { useNavigate } from 'react-router-dom';

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline'; import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import FormInput from '../../shared/components/FormInput';
import { mobileTheme } from '../../themeMobile';
// import useAuth from '../../shared/hooks/useAuth';

const BrochuresTable = (props) => {
    const { getBrochures, setBrochureStatus, notifyAdvertiserOnLandingPageCreated } = useBrochureService()
    const [brochures, setBrochures] = useState([]);
    const [popupError, setPopupError] = useState('');
    const [popupInfo, setPopupInfo] = useState('');
    const [reRender, setReRender] = useState(0);

    const [popupBrochureId, setPopupBrochureId] = useState(null);
    const [vendorEmail, setVendorEmail] = useState(null);

    const navigate = useNavigate();

    const editBrochure = async (brochureId, type) => {
        if (type == BROCHURE_TYPES.LANDING_PAGE_AD.code) {
            navigate(`/admin/brochure/manage/${brochureId}`);
        }
        if (type == BROCHURE_TYPES.PASS_THROUGH_AD.code) {
            navigate(`/admin/brochure/google-ad/manage/${brochureId}`);
        }
        if (type == BROCHURE_TYPES.BROCHURE_AD.code) {
            navigate(`/admin/brochure/brochure-ad/manage/${brochureId}`)
        }

    }

    const PublishButton = (props) => {
        if (props.status === STATUS_CODES.PENDING_REVIEW) {
            return (
                <>
                    <Link onClick={() => publishBrochure(props.brochureId)} href='#' >
                        <Tooltip title="Publish Brochure">
                            <PublishedWithChangesIcon color='primary' />
                        </Tooltip>
                    </Link>
                    &nbsp; &nbsp;
                </>
            );
        } else if (props.status === STATUS_CODES.PUBLISHED) {
            return (
                <>
                    <Tooltip title="Publish Brochure">
                        <PublishedWithChangesIcon color='disabled' />
                    </Tooltip>
                    &nbsp; &nbsp;
                </>
            );
        } else { return (<></>); }
    }

    const publishBrochure = async (brochureId) => {
        let response = await setBrochureStatus(brochureId, STATUS_CODES.PUBLISHED);
        if (response.id) setReRender(reRender + 1);
    }

    const archiveBrochure = async (brochureId) => {
        let response = await setBrochureStatus(brochureId, STATUS_CODES.ARCHIVED);
        if (response.id) setReRender(reRender + 1);
    }

    const unarchiveBrochure = async (brochureId) => {
        let response = await setBrochureStatus(brochureId, STATUS_CODES.PENDING_REVIEW);
        if (response.id) setReRender(reRender + 1);
    }

    const getUniqueCode = () => {
        return new Date().getTime();
    }

    const handleCreateLoginEmail = (index) => {
        setVendorEmail(brochures[index].contactEmail);
        setPopupBrochureId(brochures[index].id);    // Opens popup
    }

    const sendEmail = async (receipientEmail) => {
        const popupErrorMessage = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(receipientEmail) ? '' : 'Please enter a valid email address.'
        if (popupErrorMessage) {
            setPopupError(popupErrorMessage)
        } else {
            setPopupError('')
            let response = await notifyAdvertiserOnLandingPageCreated(popupBrochureId, receipientEmail);
            if (response.did) setPopupInfo('Invitation email will be sent to vendor shortly.');
        }
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 90, hide: false },
        { field: 'index', headerName: 'Index', width: 90 },
        { field: 'brochureTitle', headerName: 'Title', flex: 1 },
        { field: 'category', headerName: 'Category', width: 100 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'viewCount', headerName: 'View Count', width: 100 },
        {
            field: 'thumbnail', headerName: 'Thumbnail', width: 100, sortable: false,
            renderCell: (params) => {
                return (
                    <Avatar src={params.value} />
                );
            }
        },
        {
            field: 'actions', headerName: 'Actions', width: 160, sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Link onClick={() => editBrochure(params.value.id, params.value.type)} href='#' >
                            <Tooltip title="Edit Brochure">
                                <CreateOutlinedIcon color='primary' />
                            </Tooltip>
                        </Link>

                        &nbsp; &nbsp;
                        <PublishButton status={params.value.status} brochureId={params.value.id}></PublishButton>

                        <Link href={`https://localoffer4u.s3.ap-southeast-2.amazonaws.com/brochure-qrcodes/${params.value.id}.PNG?cache=${getUniqueCode()}`}
                            download={`${params.value.id}`}
                        >
                            <Tooltip title="Download QR code">
                                <DownloadForOfflineOutlinedIcon color='primary' />
                            </Tooltip>
                        </Link>

                        &nbsp; &nbsp;

                        <Link onClick={() => {console.log('Id :>> ', params.value); handleCreateLoginEmail(params.value.index)}} href='#' >
                            <Tooltip title="Create Login for the Vendor">
                                <PersonIcon color='primary' />
                            </Tooltip>
                        </Link>

                        &nbsp; &nbsp;

                        {params.value.status === STATUS_CODES.ARCHIVED ?
                            <Link onClick={() => unarchiveBrochure(params.value.id)} href='#' > {/*unarchive here*/}
                                <Tooltip title="Unarchive Brochure">
                                    <UnarchiveOutlinedIcon color='primary' />
                                </Tooltip>
                            </Link>
                            :
                            <Link onClick={() => archiveBrochure(params.value.id)} href='#' >
                                <Tooltip title="Archive Brochure">
                                    <ArchiveIcon color='primary' />
                                </Tooltip>
                            </Link>
                        }

                    </>
                );
            }
        },
    ];

    useEffect(() => {
        async function getData() {
            let data;
            if (props.archived) {
                data = await getBrochures('', '', '', '', `${STATUS_CODES.ARCHIVED}`, [BROCHURE_TYPES.PASS_THROUGH_AD.code, BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.BLD_AD.code, BROCHURE_TYPES.BROCHURE_AD.code]);
            } else {
                data = await getBrochures('', '', '', '', `${STATUS_CODES.PUBLISHED}, ${STATUS_CODES.PENDING_REVIEW}`, [BROCHURE_TYPES.PASS_THROUGH_AD.code, BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.BLD_AD.code, BROCHURE_TYPES.BROCHURE_AD.code]);
            }

            if (data.content !== undefined) { // undefined if empty
                setBrochures(data.content);
            }
        }
        getData();
    }, [reRender])

    const rows = brochures.map((brochure, index) => {
        let img = brochure.assets.filter(asset => asset.type === ASSET_TYPE.BROCHURE_THUMBNAIL_IMAGE)[0]?.url
        if (img == null) {
            img = brochure.assets.filter(asset => asset.type === ASSET_TYPE.BROCHURE_IMAGE)[0]?.url
        }
        return ({
            id: brochure.did,
            index: brochure.adIndex,
            vendorCompany: brochure.vendor.companyName,
            category: brochure.category?.name,
            brochureTitle: brochure.title ? brochure.title : brochure.vendor.companyName,
            status: brochure.status.description,
            type: brochure.type,
            viewCount: brochure.detailViewsCount,
            //thumbnail: brochure.assets.filter(asset => asset.type === ASSET_TYPE.BROCHURE_THUMBNAIL_IMAGE)[0]?.url,
            thumbnail: img,
            // actions: [brochure.status.code, brochure.id, brochure.type],
            actions: {
                status: brochure.status.code,
                id: brochure.id,
                type: brochure.type,
                index: index
            }
        });
    })

    const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }]);

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                sortModel={sortModel}
                onSortModelChange={(newModel) => setSortModel(newModel)}
            />
            {/* Popup */}
            <Modal
                open={popupBrochureId !== null}
                onClose={() => setPopupBrochureId(null)}
                aria-labelledby="Vendor Email Input"
                aria-describedby="Vendor Email Input"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
                >
                    <form>
                        {popupError && <Typography color={mobileTheme.palette.error.main} sx={{ mb: 1 }}>{popupError}</Typography>}
                        {popupInfo && <Typography color={mobileTheme.palette.primary.main} sx={{ mb: 1 }}>{popupInfo}</Typography>}
                        <FormInput
                            name="email"
                            label="Enter vendor's email. This will be his username for the login."
                            type="text"
                            value={vendorEmail}
                            required={true}
                            onChange={(e) => setVendorEmail(e.target.value)}
                        >
                        </FormInput>
                        <Button variant='contained' onClick={() => sendEmail(vendorEmail)} sx={{ mt: 1, float: 'right' }}> Submit </Button>
                        <Button variant='contained' onClick={() => setPopupBrochureId(null)} sx={{
                            mt: 1, mr: 1, float: 'right', 
                            backgroundColor: (mobileTheme) => mobileTheme.palette.grey[500], 
                            '&:hover': {backgroundColor: (theme) => theme.palette.grey[600], 
                            },
                        }}> Close </Button>
                    </form>
                </Box>
            </Modal>
        </div>
    )
}

export default BrochuresTable