import { Link, Outlet } from 'react-router-dom'
import React, { useState } from 'react'
import TitleBarAboveSM from '../mobile/TitleBarAboveSM'
import { MobileContext } from '../shared/context/MobileContext'
import { ThemeProvider } from '@emotion/react'
import { mobileTheme } from '../themeMobile';
import { Box, Typography, useMediaQuery } from '@mui/material'
import TitleBarSM from '../mobile/TitleBarSM'
import Footer from '../shared/components/Footer'
import { BROCHURE_TYPES } from '../shared/constants/AppConsts'
import { CommonContext } from '../shared/context/CommonContext'
import { removeScrollPosition } from '../shared/util/utils'

const MobileLayout = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedMapArea, setSelectedMapArea] = useState(null);
    const [BLDfilter, setBLDfilter] = useState(false)
    const [BROfilter, setBROfilter] = useState('')
    const [BROfilterText, setBROfilterText] = useState('BROCHURES')
    const [loading, setLoading] = useState(false)

    const aboveSM = useMediaQuery(mobileTheme.breakpoints.up('md'));

    const toggleBLDfilter = () => {
        if (BLDfilter) {
            setBLDfilter(false)
        } else {
            setBLDfilter(true)
        }
    }

    const toggleBROfilter = (filterValue) => {
        removeScrollPosition()
        setBROfilter(filterValue)
        setBROfilterText(filterValue === '' ? 'BROCHURES' : 'ALL TYPES')
    }

    return (
        <ThemeProvider theme={mobileTheme}>
            <section className='mobile-background'>
                <CommonContext.Provider value={{ loading, setLoading }}>
                    <MobileContext.Provider value={{ selectedCategory, setSelectedCategory, selectedMapArea, setSelectedMapArea, BLDfilter, toggleBLDfilter, BROfilter, toggleBROfilter, BROfilterText }}>
                        {aboveSM ? <TitleBarAboveSM info={props.info} /> : <TitleBarSM info={props.info} />}

                        {/* <Box id="watermark">maps4u demo</Box> */}
                        {aboveSM ?
                            <Box sx={{ mt: '90px' }}>
                                <Outlet />
                                <Footer />
                            </Box> :
                            <Box>
                                <Outlet />
                                <Footer />
                            </Box>
                        }
                        {/* <BottomBar /> */}
                    </MobileContext.Provider>
                </CommonContext.Provider>
            </section>
        </ThemeProvider>
    )
}

export default MobileLayout
